// app/frontend/controllers/image_preview_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "preview"]

  connect() {
    this.updatePreview()
  }

  previewImage(event) {
    this.updatePreview()
  }

  updatePreview() {
    const input = this.inputTarget
    const preview = this.previewTarget
    const field = input.dataset.imagePreviewField

    if (input.files && input.files[0]) {
      const reader = new FileReader()

      reader.onload = (e) => {
        preview.innerHTML = `<img src="${e.target.result}" class="object-cover rounded" style="max-width: 200px; max-height: 150px;">`
      }

      reader.readAsDataURL(input.files[0])
    } else if (preview.querySelector('img')) {
      // If there's an existing image and no new file selected, do nothing
    } else {
      preview.innerHTML = '<p>No image selected</p>'
    }
  }
}